export const homeSections = {
  HERO: 'hero',
  PROJECTS: 'projects',
  TECHS: 'techs',
  BLOG: 'blog',
  CONTACT: 'contact'
}

export const root = {
  MAIN_COLOR: '#299fca',
  MAIN_RGB: '41,159,202',
}

export const headerHeight = 60